@for $i from 1 through 250 {
    .wt-#{$i*5} {
        width:#{$i*5}px;
    }

    .min-wt-#{$i*5} {
        min-width:#{$i*5}px;
    }

    .max-wt-#{$i*5} {
        max-width:#{$i*5}px;
    }

    .ht-#{$i*5} {
        height:#{$i*5}px;
    }

    .max-ht-#{$i*5} {
        max-height:#{$i*5}px;
    }
}

.wt-20p {
    width: 20%;
}

.wt-33p {
    width: 25% !important;
    margin-bottom: 15px;
}

.wt-25p {
    width: 25%;
}

.wt-30p {
    width: 30%;
}

.wt-40p {
    width: 40%;
}

.wt-48p {
    width: 48%;
}

.wt-49p {
    width: 49%;
}

.wt-50p {
    width: 50%;
}

.wt-60p {
    width: 60%;
}

.wt-70p {
    width: 70%;
}

.wt-80p {
    width: 80%;
}

.wt-90p {
    width: 90%;
}

.wt-95p {
    width: 95%;
}

.wt-100p {
    width: 100%;
}

.max-wt-100p {
    max-width: 100%;
}

.ht-100p {
    height: 100%;
}

.wt-40vw {
    width: 40vw;
}

.wt-80vw {
    width: 80vw;
}

.wt-70vw {
    width: 70vw;
}

.wt-90vw {
    width: 90vw;
}

.wt-100vw {
    width: 100vw;
}

.ht-100vh {
    height: 100vh;
}

.ht-90vh {
    height: 90vh;
}

.ht-95vh {
    height: 95vh;
}

.border-1 {
    border-width: 1px;
    border-style: solid;
}

.bb-1 {
    border-bottom-width: 1px;
    border-style: solid;
}

.br-1 {
    border-right-width: 1px;
    border-style: solid;
}

.bl-0 {
    border-left-width: 0;
}

.br-0 {
    border-right-width: 0;
}

.bt-0 {
    border-top-width: 0;
}

.bb-0 {
    border-bottom-width: 0;
}

.searchImageHover:hover {
    z-index: 5;
    width: 250px;
    height: 225px;
    position: absolute;
    transition: all 0.3s ease-out;
    background: white;
    padding: 10px;
    box-shadow: 9px 9px 7px rgba(0, 0, 0, 0.25);
}

.bt-1 {
    border-top-width: 1px;
    border-style: solid;
}

.ht-max-content {
    height: max-content;
}

.obj-contain {
    object-fit: contain;
}
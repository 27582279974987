.horizontal-section {
  padding: 92px 120px;

  &.gradient-row .container {
    flex-direction: row-reverse;
  }
}

@media (max-width: $max-width-for-mobile) {
  .horizontal-section {
    padding: 36px 10px;

    &.gradient-row .container {
      flex-direction: column-reverse;
    }

    .main {
      &-data {
        padding-left: 12px;
        padding-right: 12px;
      }

      &-text {
        margin-bottom: 0;
        line-height: 28px;
      }
    }

    h2 {
      font-size: 30px;
      margin-bottom: 0;
    }

    img container {
      padding: 32px 60px;
      padding-bottom: 0;
    }
  }
}
.data-entry {}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown {

  .dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
    width: 250px;
  }

  button {
    width: 180px;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    display: flex;
    align-items: center;

    .dropdown-heading {
      width: 95%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .dropdown-item {
    white-space: normal;
  }
}

@media (max-width: $max-width-for-mobile) {
  .data-entry {
    .btn-container {
      justify-content: center;
      width: 100%;

      .btn {
        margin: 12px;
      }
    }
  }

  .rate-list {
    .rate-row {
      flex-wrap: wrap;


      div {
        width: 90%;
      }
    }
  }

  .dropdown {

    button {
      width: 250px;
    }
  }
}

@media (min-width: $max-width-for-mobile) {

  input[name="weight"],
  input[name*="date"],
  input[name="docket_discount"],
  div[name="docket_mode"] {
    width: 120px;

    * {
      max-width: 120px;
    }
  }
}
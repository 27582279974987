.modal-container {
  max-width: 90%;

  &.full-modal {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    margin: 0;
    .modal-content {
      min-height: 100vh;
      height: 100vh;
      width: 100vw;
      margin: 0;
    }
  }

  &.signup {
    max-width: 30%;

    .modal-content {
      max-height: 360px;
      min-height: 190px;
      height: fit-content;
    }
  }

  .modal-content {
    min-height: 70vh;
  }

  .modal-body {
    overflow: scroll;
  }
}

.modal-backdrop.full-page-solid-backdrop.show {
  background: white;
  opacity: 1;
}

@media (max-width: $max-width-for-mobile) {
  .modal-container {
    max-width: 100vw;
    margin: 0;

    &.signup {
      max-width: 100vw;
    }

    .modal-content {
      min-height: 100vh;
    }
  }
}

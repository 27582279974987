@import '~bootstrap/scss/bootstrap.scss';
@import "./stylekit/variables";
@import './stylekit/colors';
@import './pageloader.scss';
@import './stylekit/flex';
@import './stylekit/margin';
@import './stylekit/padding';
@import './stylekit/dimensions';
@import './stylekit/spacing';
@import './stylekit/fonts';
@import './stylekit/utils';
@import './stylekit/responsive';
@import './Shimmer.scss';
@import './header';
@import './card';
@import './stylekit/icons';
@import './aboutUs';
@import './servicies';
@import './footer';
@import './subPageTheme';
@import './accordian';
@import './dataEntry';
@import './modalContainer';
@import './printScreen';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	min-height: 30vh;
	min-width: 100%;
	font-family: "Montserrat", sans-serif;
	background-color: #efeff4;

}

.link {
	color: blue;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

button:disabled {
	cursor: not-allowed;
}

@media only screen and (min-width: 300px) and (max-width: 410px) {
	.email-block {
		display: block;

		.email-icon {
			margin-left: unset;
		}
	}
}

.not-found {
	font: 110%/1.5 system-ui,
		sans-serif;
	background: #131417;
	color: white;
	height: 100vh;
	margin: 0;
	display: grid;
	place-items: center;
	padding: 2rem;
}

.form-control {

	&[type="radio"],
	&[type="checkbox"] {
		height: 20px;
		display: inline;
		width: 20px;
		margin-right: 8px;
		margin-bottom: 0.5rem;
	}
}
#print-area {
  display: none;
}

@media print {

  .printing {
    * {
      font-size: 11pt;
    }

    background-color: white;

    #print-area {
      display: block;
    }

    #root,
    .modal-container,
    .fade {
      display: none;
    }
  }
}

table.invoice-print {
  background-color: #FFFFFF;
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  td,
  th {
    border: 0.5px solid #000000;
    padding: 1px 5px;
  }

  tbody td {
    color: #000000;
  }

  thead {
    border-bottom: 2px solid #000000;

    th {
      font-size: 12pt;
      font-weight: bold;
      color: #000000;
    }
  }

  tfoot {
    td {
      font-size: 14px;
    }

    .links {
      text-align: right;

      a {
        display: inline-block;
        background: #1C6EA4;
        color: #FFFFFF;
        padding: 2px 8px;
        border-radius: 5px;
      }
    }
  }
}

.font57105 {
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
}

.xl657105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: middle;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl667105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: center;
  vertical-align: middle;
  border: .5pt solid windowtext;
  background: yellow;
  mso-pattern: black none;
  white-space: nowrap;
}

.xl677105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: center;
  vertical-align: middle;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl687105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: middle;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl697105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: center;
  vertical-align: middle;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl707105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: middle;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl717105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: center;
  vertical-align: middle;
  border-top: .5pt dotted windowtext;
  border-right: none;
  border-bottom: .5pt solid windowtext;
  border-left: none;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl727105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: middle;
  border-top: .5pt solid windowtext;
  border-right: none;
  border-bottom: .5pt dotted windowtext;
  border-left: none;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl737105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: center;
  vertical-align: middle;
  border-top: .5pt solid windowtext;
  border-right: none;
  border-bottom: .5pt dotted windowtext;
  border-left: none;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl747105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: top;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: nowrap;
}

.xl757105 {
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  mso-number-format: General;
  text-align: left;
  vertical-align: top;
  border: .5pt solid windowtext;
  mso-background-source: auto;
  mso-pattern: auto;
  white-space: normal;
}
@for $i from 8 through 40 {
    .fs-#{$i} {
        font-size:#{$i}px;
    }
}

.fw-bold {
    font-weight: 500;
}

.fw-bolder {
    font-weight: 700;
}

.lh-16 {
    line-height: 16px;
}

.lh-36 {
    line-height: 36px;
}

.lh-22 {
    line-height: 22px;
}

.lh-10 {
    line-height: 10px;
}

.f-small-caps {
    font-variant: small-caps;
}

.t-justify {
    text-align: justify;
}

.fs-9-5pt {
    font-size: 9.5pt;
}

.fs-10pt {
    font-size: 10.0pt;
}

.fs-11pt {
    font-size: 11.0pt;
}

.fs-12pt {
    font-size: 12.0pt;
}

.fs-13pt {
    font-size: 13.0pt;
}
.check-icon:before {
  content: "\2713";
}

.cross-icon:before {
  content: "\00D7";
}

.phone-icon:before {
  content: "\01F4DE	";
}

.email-icon:before {
  content: "\1F4E7 ";
}
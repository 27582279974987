.c-pr {
  color: $primary-theme-text-color !important;
}

.bg-pr {
  background-color: $primary-theme-bg-color !important;
}

.c-sc {
  color: $secondary-theme-text-color !important;
}

.bg-sc {
  background-color: $secondary-theme-bg-color !important;
}

.c-tr {
  color: $tertiary-theme-text-color !important;
}

.bg-tr {
  background-color: $tertiary-theme-bg-color !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-none {
  background: none;

  :hover,
  :focus,
  :active {
    background: none;
  }
}

.c-white {
  color: #ffffff;
}

.bg-lightBlue {
  background-color: #e8f4f8;
}

.bc-error {
  border: 1px solid #e60000;
}

.c-error {
  color: #e60000;
}

.bg-silver {
  background-color: #C0C0C0;
}

.bg-yellow {
  background-color: yellow;
}
.shimmerContainer {
  border: none;
  width: 100%;
  margin: 0px auto;
}

.shimmerWrapper {
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.headerShimmer {
  height: 170px;
  width: 100%;
}

.boxShimmer {
  background: #777;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}


.shimmerAnimate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
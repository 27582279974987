.footer-distributed {
  background: #0c0c0c2e;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 10px 50px;

  .footer-left {
    display: inline-block;
    vertical-align: top;
    width: 40%;

    .navbar-brand {

      &:hover,
      &:focus {
        color: $tertiary-theme-text-color;
      }
    }
  }

  .footer-center {
    display: inline-block;
    vertical-align: top;
    width: 35%;

    p {
      display: inline-block;
      color: #ffffff;
      font-weight: 400;
      vertical-align: middle;
      margin: 0 15px;
    }

    div {
      margin: 10px 0;
    }
  }

  .footer-right {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }

  h3 {
    color: #ffffff;
    font: normal 36px 'Open Sans', cursive;
    margin: 0;

    span {
      color: lightseagreen;
    }
  }

  .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;

    a {
      display: inline-block;
      line-height: 1.8;
      font-weight: 400;
      text-decoration: none;
      color: inherit;

      &:before {
        content: "|";
        font-weight: 300;
        font-size: 20px;
        left: 0;
        color: #fff;
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    .link-1 {
      &:before {
        content: none;
      }
    }
  }

  .footer-company-name {
    color: #222;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;

    span {
      display: block;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .footer-icons {
    margin-top: 25px;

    a {
      display: inline-block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-color: #33383b;
      border-radius: 2px;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      line-height: 35px;
      margin-right: 3px;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;

    .footer-left {
      display: block;
      width: 100%;
      margin-bottom: 25px;
      text-align: center;
    }

    .footer-center {
      display: block;
      width: 100%;
      margin-bottom: 25px;
      text-align: center;
    }

    .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 25px;
      text-align: center;
    }
  }
}